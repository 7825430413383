@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");

$lightBlue: #6dc6ff;
$darkBlue: #2b6cb0;
$black: #1a1a1a;
$gray: #575252;
$white: #f7fafc;
$red: rgb(201, 40, 40);
$lightRed: rgb(206, 88, 88);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  background-color: #fff;
  font-family: montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

img {
  max-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
