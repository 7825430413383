@use "../../assets/style.scss" as *;

@mixin formInput() {
  font-size: 1rem;
  padding: 0.25em;
  border: 2px solid $lightBlue;
  border-radius: 0.25rem;
  transition: border 200ms ease;
  color: $black;
  background-color: $white;
  width: 25ch;
  margin-bottom: 0.5rem;
  &::placeholder {
    color: $gray;
  }
  &:hover,
  &:focus {
    border: 2px solid $darkBlue;
  }

  @media screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: center;
  background-color: $white;
  text-transform: uppercase;
  padding-block: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.125);
  &__image {
    width: min(500px, 80vw);
    display: block;
    mix-blend-mode: multiply;
  }
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.form-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 5rem;
  &__field {
    display: block;
    margin-bottom: 0.5rem;
    input,
    select {
      @include formInput();
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 2rem;
    }
  }

  &__divider {
    width: 100%;
    height: 2px;
    border-bottom: 2px dotted $darkBlue;
    margin-block: 1rem;
  }

  &__button {
    font-size: 1rem;
    background-color: $lightBlue;
    border: none;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    padding: 1em 2em;
    border-radius: 0.25rem;
    border: 2px solid $lightBlue;
    transition: all 200ms ease;
    &:disabled {
      background-color: $gray;
      border: 2px solid $gray;
    }
    cursor: pointer;
    &:hover {
      background-color: $darkBlue;
      border: 2px solid $darkBlue;
    }
    &--secondary {
      background-color: $white;
      color: $lightBlue;
      border: 2px solid $lightBlue;
      &:disabled {
        color: $gray;
        border: 2px solid $gray;
      }
      &:hover {
        background-color: $white;
        color: $darkBlue;
        border: 2px solid $darkBlue;
      }
    }

    &--danger {
      background-color: $lightRed;
      color: $white;
      border: 2px solid $lightRed;
      &:hover {
        background-color: $red;
        border: 2px solid $red;
      }
    }
    &--small {
      font-size: 0.75rem;
    }
  }

  &__error {
    color: $red;
    display: block;
    font-size: 0.75rem;
    margin-bottom: 0.1rem;
  }

  &__input {
    @include formInput();
  }

  &__group {
    margin-bottom: 3rem;
    @media screen and (max-width: 600px) {
      margin-bottom: 2rem;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.1rem;
  }

  &__canvas {
    width: min(600px, 100%);
    height: min(200wv, 400px);
    &:hover,
    &:focus {
      border: 2px solid $darkBlue;
    }
    border: 2px solid $lightBlue;
    border-radius: 0.25rem;
    transition: border 200ms ease;
  }

  &__header {
    margin-bottom: 0.5rem;
    color: $gray;
    font-size: clamp(1rem, 5vw, 1.5rem);
    &--main {
      margin-bottom: 2rem;
      font-size: clamp(1rem, 5vw, 2rem);
      color: $black;
    }
  }
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  width: min(90vw, 500px);
  padding-block: 2rem;
  text-align: center;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: 0 0 0 200vmax rgba(0, 0, 0, 0.85);
  z-index: 10;
  &__header {
    margin-bottom: 1rem;
  }
}
