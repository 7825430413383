@use "../../assets/style.scss" as *;

.wrapper {
  margin-top: 2rem;
  margin-inline: auto;
  @media screen and (min-width: 600px) {
    width: clamp(600px, 70vw, 1000px);
  }
}

.a_header {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.search-input {
  display: flex;
  font-size: 1rem;
  & > * {
    cursor: pointer;
    transition: all 200ms ease-in-out;
    padding: 0.25em 0.5em;
    font-size: inherit;
    border: 2px solid $lightBlue;
  }
  input {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    &:hover,
    &:focus-visible {
      border: 2px solid $darkBlue;
    }
  }
  button {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: $lightBlue;
    color: $white;
    &:hover,
    &:focus-visible {
      background-color: $darkBlue;
      border: 2px solid $darkBlue;
    }
  }
}

.flex-out {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.select {
  transition: all 200ms ease-in-out;
  font-size: 1rem;
  border: 2px solid $lightBlue;
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: $darkBlue;
    border: 2px solid $darkBlue;
  }
  & > * {
    background-color: $darkBlue;
  }
}

.btn {
  transition: all 200ms ease-in-out;
  font-size: 1rem;
  border: 2px solid $lightRed;
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
  background-color: $lightRed;
  color: $white;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: $red;
    border: 2px solid $red;
  }
  & > * {
    background-color: $red;
  }
}

.btn-options {
  transition: all 200ms ease-in-out;
  font-size: 1rem;
  border: 2px solid $lightBlue;
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: $darkBlue;
    border: 2px solid $darkBlue;
  }
  & > * {
    background-color: $darkBlue;
  }
}

table {
  //   border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border: 2px solid $darkBlue;
  background-color: $darkBlue;
}

td {
  font-size: 0.75rem;
  padding: 0.5em 0.5em;
  @media screen and (min-width: 600px) {
    font-size: 1rem;
  }
}

td:last-child {
  text-align: center;
}

tr:nth-child(2n) {
  background-color: $white;
}

tr:nth-child(2n + 1) {
  background-color: $lightBlue;
}

tr:first-child {
  background-color: $darkBlue;
  text-align: center;
  font-size: 1.1em;
  color: $white;
  font-weight: 700;
}

.page-btn {
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
  font-size: 1rem;
  border: 2px solid $lightBlue;
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background-color: $darkBlue;
    border: 2px solid $darkBlue;
  }
  & > * {
    background-color: $darkBlue;
  }
}
